<template>

  <dash-page-new
      :title="$store.state.settings.pageData.title"
      :subtitle="$store.state.settings.pageData.subtitle || $t('Education') "
      :root="$store.state.settings.pageData.root || $t('Dashboard')"
      :root-location="$store.state.settings.pageData.rootLocation || businessDashLink()"
      icon="mdi-school-outline"
      :side-navigation="navigation"
      :navigation-route="$store.state.settings.pageData.navigationRoute || 'education' "
      :navigation-return-route="$store.state.settings.pageData.navigationReturnRoute || ''"

      :filters="$store.state.settings.pageData.filters || []"
      :filters-input="$store.state.settings.filtersData || {}"
      @filter="$store.state.settings.filtersData = $event"

  >

    <template #header_action>
        <ws-button
            v-if="$store.state.settings.pageData.headerAction && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            @click="$store.state.settings.pageData.headerAction()"
            :label-raw="$store.state.settings.pageData.headerActionText"
            :block="SM"
            :class="[{'mt-3' : SM}]"
        />

    </template>

    <template #default>
      <router-view></router-view>
    </template>

  </dash-page-new>

</template>

<script>
export default {
  name: "DashEducation",
  data() {
    return {
    }
  },
  computed : {
    langsSelect() {
      return [
        { text : 'UA' , value : 'ua' },
        { text : 'EN' , value : 'en' }
      ]
    },
    navigation() {
      return  [
        { text : this.$t('EducationPrograms')  ,
          value : 'education_programs' ,
          path : 'education_programs' ,
          icon : 'mdi-image-text'
        },
        { text : this.$t('StudyPlans')         ,
          value : 'study_plans'    ,
          path : 'study_plans'    ,
          icon : 'mdi-account-multiple'
        },
        // { text : this.$t('WorkPrograms')       ,
        //   value : 'work_programs'    ,
        //   path : 'work_programs'    ,
        //   icon : 'mdi-account-multiple'
        // },
      ]
    }
  },
  beforeMount() {
    this.$store.state.structure.selectedLang = 'ua'
  }
}
</script>

<style scoped>

</style>